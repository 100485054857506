import { mixin } from "@/mixins/mixin";

export default {
  allTickets: (state) => {
    return state.ticket
  },
  crrTicket: (state) => {
    console.log("jdcnkj", state.crrTicket)

    return state.crrTicket
  },
}
