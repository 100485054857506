import { FETCH_PROJET_CONTRAT,
   GET_PROJET_CONTRAT,
   FETCH_SITUATION,
   UPDATE_SITUATION,
   ADD_PROJECT_Quittance,
   } from "./mutation-types";

import service from "@/service";
import config from "@/config";
import axios from "axios";

const $service = service(axios, config);

export default {

  async applyGetAllSituation({commit},data){
    const situations = await $service.getAllReceipt(data)
     commit (FETCH_SITUATION, situations.data);
  },
  async applyToPaye({commit},data){
    commit(UPDATE_SITUATION, data)
  },
  async applyGetAllProjetContrat({ commit }, data) {
    commit(FETCH_PROJET_CONTRAT,
      {
        list: [],
        total: 0
      })
    const projects = await $service.getAllProject(data)
    commit(FETCH_PROJET_CONTRAT, projects.data)

    return projects.data
  },

  async applyGetProjetContrat({ commit }, data) {
    const project = await $service.getProject(data);
    commit(GET_PROJET_CONTRAT, project.data);
  },
  async applyPutAvenant({ commit }, data) {
    return await $service.putAvenant(data);
  },
  async applyPutQuittance({ commit }, data) {
    const project = await $service.putQuittance(data);
    commit(ADD_PROJECT_Quittance, project.data);
    return project.data.quittances
  },
  async applyChangeQuittanceState({ commit }, data) {
    const project = await $service.changeQuittanceState(data);
    commit(UPDATE_PROJECT, project.data);
  },
};
