export default {
  projetContrat: {
    list: [],
    total: 0
  },

  crrProjetContrat: {},
  situations: {
    list: [],
    total: 0
  },
  crrSituation: {},

  
}
