import {
} from './mutation-types'

import service from '@/service'
import config from '@/config'
import axios from 'axios'

const $service = service(axios, config)


export default {

  async applyUploadFile ({commit}, data) {
    return await $service.uploadFile(data)
  },
  async applyGetSetting ({commit}, data) {
    return await $service.getSetting(data)
  },
  async applyAddSetting ({commit}, data) {
    return await $service.addSetting(data)
  },
  async applyPutSetting ({commit}, data) {
    return await $service.putSetting(data)
  },
  async applyRemoveSetting ({commit}, data) {
    return await $service.removeSetting(data)
  },
  async applyGetAllNotification ({commit}, data) {
    return await $service.getAllNotification(data)
  },
  async applyReadNotification({commit},data){
    return await $service.readNotification(data)
  },
  async applyGetItemDocument({ commit }, data) {
    return await $service.getItemDocument(data)
  },
  async applyGenerateCotisation ({commit}, data) {
    return await $service.generateCotisation(data)
  },
  async applyGenerateCotisationSolde ({commit}, data) {
    return await $service.generateCotisationSolde(data)
  },
}
