export default {
  user: (state) => {
    return state.user;
  },

  userId: (state) => {
    return state.user && state.user.id;
  },
  parentCode:(state) => {
    return state.user.parentCode;
  },
  authenticated: (state) => {
    return state.authenticated;
  }
};
