/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { AUTH_TOKEN_KEY } from "@/constants";

export default ($http, $config) => {
  const $api = $http.create({
    baseURL: $config.serverURL,
    headers: { "Content-Type": "application/json" }
  });

  $api.interceptors.request.use(
    config => {
      const authToken = sessionStorage.getItem(AUTH_TOKEN_KEY);
      if (authToken) {
        //config.headers["Access-Token"] = sessionStorage.getItem(AUTH_TOKEN_KEY);
        config.headers["Authorization"] = `Bearer ${authToken}`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  )

  /**
   * Upload File
   * @param {*} data
   */
  const uploadFile = data => {
    return $api.post("/api/v1/upload-file", data).then(res => res.data);
  };

  /**
   * Login user
   * @param {*} data
   */
  const signin = data => {
    return $api.post('/api/v1/auth/signin', data)
      .then(res => res.data)
  }

  /**
   * Forget password
   * @param {*} data
   */
  const forgetPassword = data => {
    return $api.post('/api/v1/auth/forget-pwd', data)
      .then(res => res.data)
  }

  /**
   * Change password
   * @param {*} data
   */
  const changePassword = data => {
    return $api.post('/api/v1/auth/change-pwd', data)
      .then(res => res.data)
  }

  /**
   * Update user
   * @param {*} data
   */
  const putUser = data => {
    return $api.post("/api/v1/users/put", data).then(res => res.data);
  };

  /**
   *  parrainStats
   * @param {userId, parentCode} data
   */
  const parrainStats = data => {
    return $api.post("/api/v1/users/parrain/stats", data).then(res => res.data);
  };

/**
   * Add setting
   * @param {*} data
   */
  const addSetting = data => {
    return $api.post('/api/v1/setting/add', data)
      .then(res => res.data)
  }

  /**
   * Update setting
   * @param {*} data
   */
  const putSetting = data => {
    return $api.post('/api/v1/setting/put', data)
      .then(res => res.data)
  }

  /**
   * Remove setting
   * @param {*} data
   */
  const removeSetting = data => {
    return $api.post('/api/v1/setting/remove', data)
      .then(res => res.data)
  }

  /**
   * setting
   * @param {*} data
   */
  const getSetting = (params) => {
    return $api
      .get('/api/v1/setting/item', {
        params
      })
      .then(res => res.data);
  }

  /**
   * allProduct
   * @param {*} data
   */
  const getAllProduct = (params) => {
    return $api
      .get('/api/v1/product/list', {
        params
      })
      .then(res => res.data);
  }


  /**
   * Add ticket
   * @param {*} data
   */
  const addTicket = data => {
    return $api.post('/api/v1/ticket/add', data)
      .then(res => res.data)
  }

  /**
   * Update ticket
   * @param {*} data
   */
  const putTicket = data => {
    return $api.post('/api/v1/ticket/put', data)
      .then(res => res.data)
  }

  /**
   * change ticket state
   * @param {*} data
   */
  const changeTicketStatus = data => {
    return $api.post('/api/v1/ticket/allow', data)
      .then(res => res.data)
  }

  /**
   * remove ticket
   * @param {*} data
   */
  const removeTicket = data => {
    return $api.post('/api/v1/ticket/remove', data)
      .then(res => res.data)
  }


  /**
   * ticket
   * @param {*} data
   */
  const getTicket = (params) => {
    return $api
      .get('/api/v1/ticket/item', {
        params
      })
      .then(res => res.data);
  }

  /**
   * allTicket
   * @param {*} data
   */
  const getAllTicket = (params) => {
    return $api
      .get('/api/v1/ticket/list', {
        params
      })
      .then(res => res.data);
  }



  /**
   * Add comment
   * @param {*} data
   */
  const addCommentTicket = data => {
    return $api.post('/api/v1/ticket/comment/add', data)
      .then(res => res.data)
  }

  /**
   * Update comment
   * @param {*} data
   */
  const putCommentTicket = data => {
    return $api.post('/api/v1/ticket/comment/put', data)
      .then(res => res.data)
  }

  /**
   * remove comment
   * @param {*} data
   */
  const removeCommentTicket = data => {
    return $api.post('/api/v1/ticket/comment/remove', data)
      .then(res => res.data)
  }


/**
   * allNotif
   * @param {*} data
   */
  const getAllNotification = (params) => {
    return $api
      .get('/api/v1/notification/list', {
        params
      })
      .then(res => res.data);
  }
   /**
   * read notificatio
   * @param {*} data
   */
  const readNotification = data => {
    return $api.post('/api/v1/notification/view', data)
      .then(res => res.data)
  }


  /**
   * allSuscribe
   * @param {*} data
   */
  const getAllProject = (params) => {
    return $api
      .get('/api/v1/project/list', {
        params
      })
      .then(res => res.data);
  };

  /**
 * Suscribe
 * @param {*} data
 */
  const getProject = (params) => {
    return $api
      .get('/api/v1/project/item', {
        params
      })
      .then(res => res.data);
  };

   /**
   * allRECEIPT
   * @param {*} data
   */
  const getAllReceipt = (params) => {
    return $api
      .get('/api/v1/project/quittance/list', {
        params
      })
      .then(res => res.data);
  };
  /**
   * Update RECEIPT
   * @param {*} data
   */
  const putReceipt = data => {
    return $api.post('/api/v1/project/quittance/put', data)
      .then(res => res.data)
  };

  /**
   * @param{}
   * All document
   */
  const getItemDocument = (params) => {
    return $api.get('/api/v1/doc-client/item', { params }).then(res => res.data);
  }

  const putAvenant = data => {
    return $api.post('/api/v1/project/avenant/put', data)
      .then(res => res.data)
  };

  const generateCotisation = data => {
    return $api.post('/api/v1/pdf/generate/no-save/cotisation', data)
      .then(res => res.data)
  }

  const generateCotisationSolde = data => {
    return $api.post('/api/v1/pdf/generate/no-save/cotisation/solde', data)
      .then(res => res.data)
  }

  const putQuittance = data => {
    return $api.post('/api/v1/project/quittance/put', data)
      .then(res => res.data)
  }

  const changeQuittanceState = data => {
    return $api.post('/api/v1/project/quittance/allow', data)
      .then(res => res.data)
  };

  return {
    uploadFile,
    signin,
    forgetPassword,
    changePassword,
    putUser,
    parrainStats,
    addSetting, putSetting, removeSetting, getSetting,
    getAllProduct,
    addTicket, putTicket, getAllTicket, getTicket, removeTicket, changeTicketStatus,
    addCommentTicket, putCommentTicket, removeCommentTicket,
    getAllNotification, readNotification,
    getAllProject, getProject,getAllReceipt,putReceipt,
    getItemDocument, putAvenant,
    generateCotisation, generateCotisationSolde,
    changeQuittanceState, putQuittance,
  }
}
